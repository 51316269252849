import { AfterViewInit, Component, CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA, OnInit, ViewChild, ViewContainerRef, Compiler } from '@angular/core';
import { UntypedFormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { SafeHtmlPipe, SafePipe } from '@app/formio-angular-material/components/MaterialComponent';
import { MaterialModule } from '@app/shared/material.module';
import { Subject } from 'rxjs';

import { FormControlNameDirective } from '../directives/form-control-name.directive';
import { ProsemirrorEditorsService } from '../services/prosemirror-editors.service';
import { CommonModule, NgComponentOutlet } from '@angular/common';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

@Component({
  selector: 'app-editor-menu',
  templateUrl: './editor-menu.component.html',
  styleUrls: ['./editor-menu.component.scss']
})
export class EditorMenuComponent implements OnInit {
  @ViewChild('container', { read: ViewContainerRef }) container?: ViewContainerRef;

  constructor(private rposemirrorEditorsService:ProsemirrorEditorsService) { }

  ngOnInit(): void {
    this.rposemirrorEditorsService.setIntFunction(this.interpolateTemplate)
  }

  interpolateTemplate = (htmlToCompile: string, data: any, formGroup: UntypedFormGroup = new UntypedFormGroup({}), template?, options?: any) => {
    if(options?.materials) {
      htmlToCompile = htmlToCompile.replace(/<p contenteditableNode="false"/g, `<p controlPath="taxonPropLabel" contenteditableNode="false"`
      )
    }    
    let container = this.container
    const regex1 = /\[innerHTML\]="[^\"\|]+"/g

    if (options?.hTag) {
      htmlToCompile = htmlToCompile.replace(/\${level}/gm, `${options.hTag > 6 ? 6 : options.hTag}`)
    } else if (options?.hTag == 0) {
      htmlToCompile = htmlToCompile.replace(/\${level}/gm, `2`);
    }

    if (data?.tableContent) {
      data.tableContent = data.tableContent.replace(/<p[^>]*><\/p>/gm, "")
    }

    let array1;
    while ((array1 = regex1.exec(htmlToCompile)) !== null) {
      let newStr = array1[0].slice(0,array1[0].length-1)
      htmlToCompile = htmlToCompile.replace(array1[0],newStr+' | safehtml"')
    }

    function getRenderedHtml(templateString: string) {
      return new Promise(resolve => {
        let html = { template: templateString };

        let afterViewInitSubject = new Subject();

        const component = Component({
          ...html,
          styles: [':host {table: {border: red}}'],
          standalone: true,
          imports: [
            CommonModule,
            FormsModule,
            ReactiveFormsModule,
            FormControlNameDirective
          ],
          schemas: [
            CUSTOM_ELEMENTS_SCHEMA, 
            NO_ERRORS_SCHEMA
          ]
        })(class implements AfterViewInit {
          data = data ? JSON.parse(JSON.stringify(data)) : data;
          linksPrefix = options?.linksPrefix;

          formGroup = formGroup;

          getKeys(obj: any) {
            if(obj) {
              return Object.keys(obj);
            } else {
              return Object.keys(data);
            }
          }

          getCharValue(i:number){
            return String.fromCharCode(97 + i);
          }
          hasTextContent(obj: any) {
            return obj ? obj.replace(/<p[^>]*><\/p>/gm, "").trim().length > 0 : false;
          }
          hasCommonName(): boolean {
            return data?.taxonomicCoverage.some((value: any) => value.commonName);
          }
          ngAfterViewInit() {
            afterViewInitSubject.next('build');
          }
        })
        
        const componentRef = container.createComponent(component);
        afterViewInitSubject.subscribe(() => {
          afterViewInitSubject.unsubscribe();
          let clearString = componentRef.location.nativeElement.innerHTML;
          componentRef.destroy();
          resolve(clearString);
        })
      })
    }

    if(template) {
      return getRenderedHtml(`<ng-container *ngIf="formGroup" [formGroup]="formGroup">
      <div>
              <ng-container  *ngTemplateOutlet="${template}"></ng-container>
              ${htmlToCompile}
      </div></ng-container>`);
    } else {
      return getRenderedHtml(`<ng-container *ngIf="formGroup" [formGroup]="formGroup">
      <div>${htmlToCompile}
      </div></ng-container>`)
    }
  }

}
