import { Plugin, PluginKey } from "prosemirror-state";
import { EditorView } from "prosemirror-view";
import { MathView } from "./prosemirror-math";

interface IMathPluginState {
	macros: {
        [cmd: string]: string;
    };
    activeNodeViews: MathView[];
    prevCursorPos: number;
}

const MATH_PLUGIN_KEY = new PluginKey<IMathPluginState>("prosemirror-math");

export const mathPlugin = new Plugin({
	key: MATH_PLUGIN_KEY,
	state: {
		init(config, instance){
			return {
				macros: {},
				activeNodeViews: [],
				prevCursorPos: 0,
			};
		},
		apply(tr, value, oldState, newState){
			return {
				activeNodeViews : value.activeNodeViews,
				macros          : value.macros,
				prevCursorPos   : oldState.selection.from
			}
		},
	},
	props: {
		nodeViews: {
            //@ts-ignore
			"math_inline" : createMathView(false),
            //@ts-ignore
			"math_display" : createMathView(true)
		}
	}
});

function createMathView(displayMode:boolean) {
	return (node: Node, view: EditorView, getPos:boolean|(()=>number)): MathView => {
		let pluginState = MATH_PLUGIN_KEY.getState(view.state);
		if(!pluginState){ throw new Error("no math plugin!"); }
		let nodeViews = pluginState.activeNodeViews;

		//@ts-ignore
		let nodeView = new MathView(node, view, getPos as (() => number), 
			{ katexOptions : { displayMode, macros: pluginState.macros } },
			MATH_PLUGIN_KEY,
			()=>{ nodeViews.splice(nodeViews.indexOf(nodeView)); },
		);
    nodeView.selectNode = () => {};
    nodeView.deselectNode = () => {};
  
    nodeViews.push(nodeView);
		return nodeView;
	}
}

