import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { MarkType } from 'prosemirror-model';
import { EditorState, NodeSelection, Plugin, PluginKey, Transaction } from 'prosemirror-state';
import { EditorView } from 'prosemirror-view';

import { AddCommentDialogComponent } from '@app/editor/add-comment-dialog/add-comment-dialog.component';
import { ServiceShare } from '@app/editor/services/service-share.service';

@Injectable({
  providedIn: 'root',
})
export class MathButtonsService {
  mathButtonsPluginKey = new PluginKey('katexButtonsPlugin');
  mathButtonsPLugin: Plugin;

  linkButtonsClasses = ['math-edit-btn', 'math-delete-btn'];

  constructor(private dialog: MatDialog, private serviceShare: ServiceShare) {
    const self = this;

    this.mathButtonsPLugin = new Plugin({
      key: this.mathButtonsPluginKey,
      state: {
        init: (config: any, _: EditorState) => {
          return { sectionName: config.sectionName };
        },
        apply: (transaction: Transaction, value, _, newState) => {
          return value;
        },
      },
      props: {
        handleDOMEvents: {
          blur: (view, event) => {
            if (
              event.relatedTarget &&
              event.relatedTarget instanceof HTMLButtonElement &&
              this.linkButtonsClasses.includes(event.relatedTarget.className)
            ) {
              event.relatedTarget.click();
            }
          },
        },
      },
      view: function (view: EditorView) {
        return {
          update: (view: EditorView, prevState) => {
            if (JSON.stringify(view.state.doc) == JSON.stringify(prevState.doc) && !view.hasFocus()) {
              return;
            }

            self.attachButtons(view);
          },
          destroy: () => { }
        }
      },
    });
  }

  attachButtons(view: EditorView) {
    const state = view.state;
    const anchor = state.selection.$anchor;
    const nodeInfo = this.getNode(
      state,
      anchor.pos,
      state.schema.marks.link
    );

    const btnsWrapper = document.getElementsByClassName('editor_buttons_wrapper')[0] as HTMLDivElement;
    if(!btnsWrapper) return;
    const editMathBtnContainer = btnsWrapper.getElementsByClassName('math-button-container')[0] as HTMLDivElement;
    const removeMathBtnContiner = btnsWrapper.getElementsByClassName('math-delete-button-container')[0] as HTMLDivElement;
    
    if(!editMathBtnContainer) return;
    
    if(nodeInfo) {
      editMathBtnContainer.style.display = "block";
      removeMathBtnContiner.style.display = "block";

      const editButton = editMathBtnContainer.getElementsByClassName('math-edit-btn')[0] as HTMLButtonElement;
      const removeButton = removeMathBtnContiner.getElementsByClassName('math-delete-btn')[0] as HTMLButtonElement;

      editButton.removeAllListeners!("click");
      removeButton.removeAllListeners!("click");

      editButton.addEventListener("click", (event: MouseEvent) => {
        const { from, to, node, typeOfNode } = nodeInfo;
        //@ts-ignore
        const text = node.content.content[0].text;
        
        this.dialog.open(AddCommentDialogComponent, {
          width: '582px',
          panelClass: 'insert-figure-in-editor',
          data: { type: 'mathinline', mathExpression: '', text, from, to, node, typeOfNode},
        })
        .afterClosed()
        .subscribe((result) => {
          if(result && result.text.value) {
            const { text, mathType } = result;
            const mathExpresion = text.value;
            let mathNode = state.schema.nodes[mathType.value];
            let newmathNode = mathNode.create(undefined, state.schema.text(mathExpresion));
            let tr = view.state.tr.deleteRange(from - 1, to + 1);
            view.dispatch(tr);

            setTimeout(() => {
              view.dispatch(view.state.tr.insert(from, newmathNode));
            }, 100);
            // if (mathType.value == 'math_display') {
            //   view.dispatch(view.state.tr.setSelection(NodeSelection.create(tr.doc, from)));
            // }
          }
        });
      })

      removeButton.addEventListener('click', () => {
        const { from, to } = nodeInfo;
        this.serviceShare.YdocService.ydoc.getMap("change").set("change", "change");
        view.dispatch(state.tr.deleteRange(from, to + 1));
      })
    } else {
      editMathBtnContainer.style.display = "none";
      removeMathBtnContiner.style.display = "none";
    }
  }

  getNode(state: EditorState, pos: number, markType: MarkType) {
    const node = state.doc.nodeAt(pos);
    if(!node) return;
   
    if(node.type.name == "math_inline") {
      return { from: pos, to: pos + node.content.size, node, typeOfNode: 'math_inline' };
    } else if (node.type.name == "math_display") {
      return { from: pos, to: pos + node.content.size, node, typeOfNode: 'math_display' };
    }

    return null;
  }
}
